import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import Layout from "../components/Layout/Layout";
import Navbar from "../components/Navbar/Navbar";
import { publicationsApi } from "../utils/api"; // Import publicationsApi
import ArrowLeftIcon from "../icons/ArrowLeftIcon";
import { Interweave } from "interweave"; // For parsing HTML content
import styles from "./PublicationPage.module.scss";
import { format, parse } from "date-fns"; // Import date formatting

const PublicationPage = () => {
  const { publicationId } = useParams(); // Get publicationId from the route params
  const { publications } = publicationsApi; // Fetch the publications from the API
  const navigate = useNavigate();

  // Find the current publication based on the ID
  const currentPublication = publications.find(
    ({ id }) => id === Number(publicationId)
  );

  if (!currentPublication) return <div>Publication not found</div>;

  const {
    name,

    information: { abstract, extra },
  } = currentPublication;

  return (
    <Layout>
      <Navbar />

      <div className={styles.wrapper}>
        <button
          onClick={() => {
            navigate("/publications"); // Navigate back to the publications list
          }}
          className={styles.threadButton}
        >
          <ArrowLeftIcon className={styles.arrowLeft} />
          <p>Publications</p>
        </button>
        <h1 className={styles.publicationName}>{name}</h1>{" "}
        {/* Publication title */}
        {abstract && (
          <div className={styles.descriptionWrapper}>
            <p>
              <span className={styles.bold}>Abstract: </span>
              <Interweave
                className={styles.description}
                content={abstract}
              />{" "}
              {/* Render abstract content */}
            </p>
          </div>
        )}
        <div className={styles.publicationMeta}></div>
        {/* If extra information exists, map and display it */}
        {!extra || !extra.length ? null : (
          <div className={styles.descriptionWrapper}>
            {extra.map(({ title, content }) => (
              <p key={title}>
                <span className={styles.bold}>{title}: </span>
                {content}
              </p>
            ))}
          </div>
        )}
      </div>
    </Layout>
  );
};

export default PublicationPage;
