import React from "react";
import { useNavigate } from "react-router-dom";
import Layout from "../components/Layout/Layout";
import Navbar from "../components/Navbar/Navbar";

import { eventsApi } from "../utils/api"; // Import publicationsApi
import styles from "./EventsPage.module.scss";
import { format, parse } from "date-fns";

const EventsPage = () => {
  const navigate = useNavigate();
  const { events } = eventsApi;

  const formattedEventsData = events.map((event) => {
    const date = parse(event.date, "yy/MM/dd", new Date());
    return { ...event, date };
  });

  return (
    <Layout>
      <Navbar />
      <div className={styles.wrapper}>
        {formattedEventsData.length > 0 && (
          <span>
            <h1 className={styles.header}>Events</h1>
            <div className={styles.eventsListWrapper}>
              {formattedEventsData.map(
                ({ id, name, summary, date, location }) => (
                  <button
                    tabIndex={0}
                    key={id}
                    onClick={() => navigate(`/event/${id}`)}
                    className={styles.eventWrapper}
                  >
                    <div className={styles.eventInfoWrapper}>
                      <h2>{name}</h2>
                      <p>{summary}</p>
                      <span className={styles.dateAndLocation}>
                        <span>{format(new Date(date), "LLLL dd, yyyy")}</span>
                        <span>|</span>
                        <span>{location}</span>
                      </span>
                    </div>
                  </button>
                )
              )}
            </div>
          </span>
        )}
      </div>
    </Layout>
  );
};

export default EventsPage;
