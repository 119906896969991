import React from "react";
import { useNavigate } from "react-router-dom";
import Layout from "../components/Layout/Layout";
import Navbar from "../components/Navbar/Navbar";
import { publicationsApi } from "../utils/api"; // Import publicationsApi
import styles from "./PublicationsPage.module.scss";
import { format, parse } from "date-fns"; // Import date formatting

const PublicationsPage = () => {
  const navigate = useNavigate();
  const { publications } = publicationsApi; // Fetch the publications from the API

  const formattedData = publications.map((publication) => {
    const date = parse(publication.date, "yy/MM/dd", new Date());

    return { ...publication, date }; // Format the date of each publication
  });

  return (
    <Layout>
      <Navbar />
      <div className={styles.wrapper}>
        <h1 className={styles.header}>Publications</h1>
        <div className={styles.publicationsListWrapper}>
          {formattedData.map(({ id, name, summary, date, authors }) => (
            <button
              tabIndex={0}
              key={id}
              onClick={() => navigate(`/publication/${id}`)} // Navigate to the individual publication page
              className={styles.publicationWrapper}
            >
              <div className={styles.publicationInfoWrapper}>
                <h2>{name}</h2>
                <p>{summary}</p>
                <span className={styles.dateAndAuthors}>
                  <span>{format(new Date(date), "LLLL dd, yyyy")}</span>{" "}
                  <span className={styles.dateAndAuthorsDivider}>|</span>
                  <span>{authors.join(", ")}</span> {/* Display authors */}
                </span>
              </div>
            </button>
          ))}
        </div>
      </div>
    </Layout>
  );
};

export default PublicationsPage;
