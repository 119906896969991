import React, { Dispatch, SetStateAction, useEffect } from "react";
import styles from "./CallForSoundsBanner.module.scss";
import AudioPlayer from "../../components/AudioPlayer/AudioPlayer";

interface CallForSoundsBannerProps {
  setIsCallForSoundsBannerOpen: Dispatch<SetStateAction<boolean>>;
}

const CallForSoundsBanner: React.FC<CallForSoundsBannerProps> = ({
  setIsCallForSoundsBannerOpen,
}) => {
  const { pathname } = window.location;

  const handleCloseClick = () => {
    setIsCallForSoundsBannerOpen(false);
  };

  const handleMoreDetailsClick = (event: React.MouseEvent) => {
    event.preventDefault();
    window.history.pushState({}, "", "/call-for-sounds");
    setIsCallForSoundsBannerOpen(false);
    const navEvent = new PopStateEvent("popstate");
    window.dispatchEvent(navEvent);
  };

  useEffect(() => {
    if (pathname === "/call-for-sounds") {
      setIsCallForSoundsBannerOpen(false);
      return;
    }

    setIsCallForSoundsBannerOpen(true);
    return;
  }, [pathname, setIsCallForSoundsBannerOpen]);

  useEffect(() => {
    function handleEscapeKey(event: KeyboardEvent) {
      if (event.code === "Escape") {
        setIsCallForSoundsBannerOpen(false);
      }
    }

    document.addEventListener("keydown", handleEscapeKey);
    return () => document.removeEventListener("keydown", handleEscapeKey);
  }, []);

  return (
    <div
      className={styles.callForSoundsBannerWrapper}
      onClick={() => setIsCallForSoundsBannerOpen(false)}
    >
      <div className={styles.modal} onClick={(e) => e.stopPropagation()}>
        <button className={styles.closeButton} onClick={handleCloseClick}>
          close X
        </button>
        <div className={styles.callForSoundsBanner}>
          <div className={styles.textWrapper}>
            <h3>Call for Sounds! Call for Guest Curators! </h3>
            <p>
              Sound braid is inviting sounds that resonate with the theme of
              change. In keeping with this theme, submissions for this round of
              sounds will be guest-edited and remixed/braided together instead
              of appearing as individual sounds on the website. The deadline for
              both submissions and guest editor emails is November 15, 2024.
            </p>

            <div className={styles.audioPlayerWrapper}>
              <AudioPlayer
                url="https://res.cloudinary.com/dlpnk8lgg/video/upload/v1728063633/Sounds/Change_SB_qitt6f.wav"
                className={styles.audio}
              />
            </div>
          </div>
        </div>
        <button
          className={styles.detailsButton}
          onClick={handleMoreDetailsClick}
        >
          more details
        </button>
      </div>
    </div>
  );
};

export default CallForSoundsBanner;
