import React from "react";
import styles from "./AboutUsPage.module.scss";
import Layout from "../components/Layout/Layout";
import Navbar from "../components/Navbar/Navbar";
import NewWindowIcon from "../icons/NewWindowIcon";
import EmailIcon from "../icons/EmailIcon";

const AboutUsPage = () => (
  <Layout>
    <Navbar />

    <div className={styles.wrapper}>
      <div className={styles.contentWrapper}>
        <div className={styles.firstColumn}>
          <div className={styles.blurb}>
            <p>
              Sound braid is an exploration of collaborative listening and sonic
              conversations, a convergence of events that hop on and off the web
              platform with the hopes of sparking discussion, remixing, and
              moving through and with bodies in various ways. Sound braid is a
              non-institutionally sanctioned space and side-project meant for
              collaboration, inhabitation, and disruption.
            </p>
            <p>
              A mutable platform subject to change and revision, the sound braid
              website invites sounds that move, simmer, or ignite discussions.
              It aims to engage with experimental forms and to create rather
              than reproduce, to shape ecologies rather than libraries. This
              site may include or prompt non-performed or unexceptional sounds,
              dedramatized silences, quotidian dins, or failures.
            </p>
            <p>Listen. React. Respond.</p>
          </div>
          <div className={styles.contactUsWrapper}>
            <h2 className={styles.contactHeader}>Contact us</h2>
            <div className={styles.contactInfo}>
              <div className={styles.emailWrapper}>
                <h3 className={styles.emailHeader}>Email:</h3>
                <span className={styles.email}>
                  <p>info.soundbraid@gmail.com</p>
                  <a
                    className={styles.emailButton}
                    href="mailto:info.soundbraid@gmail.com"
                  >
                    <EmailIcon />
                  </a>
                </span>
              </div>
              <div>
                <h3 className={styles.twitterHeader}>Twitter:</h3>
                <span className={styles.twitter}>
                  <p>@Sound_Braid</p>
                  <a
                    className={styles.twitterButton}
                    href="https://twitter.com/Sound_Braid"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <NewWindowIcon />
                  </a>
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className={styles.biosWrapper}>
          <h2 className={styles.creatorsHeadline}>
            Co/founders and curators of Sound Braid:
          </h2>

          <section>
            <h3>Nicole Marchesseau</h3>
            <p className={styles.subTitle}>PhD student/ York University</p>
            <p>
              Nicole Marchesseau’s artistic and research practices explore
              spaces of process, method, and materiality. Her solo and
              multimedia collaborative artistic work has been featured in North
              America and overseas. Publications have ranged from topics
              exploring Surrealism and music, to Jandek, to the liminal spaces
              of Toronto’s Do-It-Yourself music scene. She has taught at Western
              and McMaster Universities and is currently working towards a PhD
              in Social Anthropology at York University, where she also
              completed a doctorate in music.
            </p>
          </section>

          <section>
            <h3 className={styles.bioName}>Johann Sander Puustusmaa</h3>
            <p className={styles.subTitle}>PhD student/ York University</p>
            <p>
              His work meanders at the intersection of anthropology, urban
              ecology and politics of nature. His research explores spatial and
              sensory experience, and the role of storytelling in urban
              environments.
            </p>
            <p>
              For a few years, Johann Sander hosted a radio show on
              anthropology, slowness and field recording on Radio IDA in
              Tallinn, Estonia.
            </p>
          </section>

          <section>
            <h3>Luisa Isidro Herrera</h3>
            <p className={styles.subTitle}>PhD student/ York University</p>
            <p>
              Luisa Isidro Herrera is a PhD Student in the Anthropology Program
              at York University. She works at the intersections of gender,
              security and violence related to the Colombian armed conflict.
              Since 2006 she is a founder and member of the non-profit
              organization Embrace Dialogue. Her research seeks to explore how
              grassroots initiatives that work creatively produce alternative
              forms of security, contribute to ensure buen vivir, and generate
              people’s creative ways to survive and live more livable lives.
            </p>
          </section>
        </div>
      </div>
    </div>
  </Layout>
);

export default AboutUsPage;
