import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import Layout from "../components/Layout/Layout";
import Navbar from "../components/Navbar/Navbar";
import { eventsApi } from "../utils/api";
import ArrowLeftIcon from "../icons/ArrowLeftIcon";
import { Interweave } from "interweave";

import styles from "./EventPage.module.scss";

const EventPage = () => {
  const { eventId } = useParams();
  const { events } = eventsApi;
  const navigate = useNavigate();

  const currentEvent = events.find(({ id }) => id === Number(eventId));

  if (!currentEvent) return <div>Event not found</div>;

  const {
    information: { abstract, extra },
  } = currentEvent;

  return (
    <Layout>
      <Navbar />

      <div className={styles.wrapper}>
        <button
          onClick={() => {
            navigate("/events");
          }}
          className={styles.threadButton}
        >
          <ArrowLeftIcon className={styles.arrowLeft} />
          <p>Events</p>
        </button>
        <h1 className={styles.eventname}>{currentEvent?.name}</h1>
        {abstract && (
          <div className={styles.descriptionWrapper}>
            <p>
              <span className={styles.bold}>Abstract: </span>
              <Interweave className={styles.description} content={abstract} />
            </p>
          </div>
        )}

        {!extra || !extra.length ? null : (
          <div className={styles.descriptionWrapper}>
            {extra.map(({ title, content }) => (
              <p>
                <span className={styles.bold}>{title}: </span>
                {content}
              </p>
            ))}
          </div>
        )}
      </div>
    </Layout>
  );
};

export default EventPage;
