import React from "react";
import AudioPlayer from "../components/AudioPlayer/AudioPlayer";
import Layout from "../components/Layout/Layout";
import Navbar from "../components/Navbar/Navbar";
import EmailIcon from "../icons/EmailIcon";

import styles from "./CallForSoundsPage.module.scss";
import { Link } from "react-router-dom";

const CallForSoundsPage = () => (
  <div className={styles.container}>
    <Layout>
      <Navbar />
      <div className={styles.callForSoundsPageWrapper}>
        <h1 className={styles.header}>
          {" "}
          Call for Sounds! Call for Guest Curators!{" "}
        </h1>

        <div className={styles.contentWrapper}>
          <div className={styles.textWrapper}>
            <p>
              Sound braid is inviting sounds that resonate with the theme of
              change. In keeping with this theme, submissions for this round of
              sounds will be guest-edited and remixed/braided together instead
              of appearing as individual sounds on the website. If you are
              interested in guest curating, please send a short email of
              interest to{" "}
              <a
                className={styles.link}
                href="mailto:info.soundbraid@gmail.com"
              >
                info.soundbraid@gmail.com
              </a>
              . For submission guidelines, please{" "}
              <Link to="/submit" className={styles.link}>
                click here
              </Link>
              . The deadline for both submissions and guest editor emails is
              November 15, 2024.
            </p>
            <p className={styles.secondaryText}>
              Sound braid continues to operate as a volunteer-run,
              non-institutionally funded space. To get involved or for more
              information visit soundbraid.org.
            </p>
          </div>

          <div className={styles.soundWrapper}>
            <div className={styles.audioPlayerWrapper}>
              <AudioPlayer
                url="https://res.cloudinary.com/dlpnk8lgg/video/upload/v1728063633/Sounds/Change_SB_qitt6f.wav"
                className={styles.audio}
              />
            </div>
          </div>

          <div className={styles.contactInfoWrapper}>
            <div className={styles.emailWrapper}>
              <h3 className={styles.emailHeader}>Send your sounds to:</h3>
              <span className={styles.email}>
                <p>info.soundbraid@gmail.com</p>
                <a
                  className={styles.emailButton}
                  href="mailto:info.soundbraid@gmail.com"
                >
                  <EmailIcon />
                </a>
              </span>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  </div>
);

export default CallForSoundsPage;
